/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/**
 * Mv Address Locator demo - Just for fast viewing purposes
 * For demo purposes only
 * @type {MvAddressLocatorDemo instance}
 */
(
    function () {
        const button = document.getElementById("select_button");
        const result_count = document.getElementById("address_count");

        const MvAddressLocatorDemo = window.MVAddressSelectorSDK;

        button.addEventListener("click", () => {
            result_count.innerHTML = 0;
            const demo = MvAddressLocatorDemo.init({
                containerId: "mv-address-locator",
                apiBasePath: "https://api-buurtmailing.myvivenda.com/api.php",
                useModal: true,
                // selectedAddress: {
                //     streetname: "Lindwurmstraße",
                //     countryCode: "de",
                //     postcode: "80337",
                //     huisnr: "40",
                // },
                zipCodeAreaMinCount: 200,
                selectedAddress: {
                    countryCode: "nl",
                    postcode: "6365CT",
                    huisnr: "5",
                    huisnr_bag_letter: "",
                    huisnr_bag_toevoeging: "",
                },
                //selectedAddress: {
                //    streetname: "Laarmannstraße",
                //    postcode: "44879",
                //    huisnr: 12,
                //    countryCode: "de",
                //},
                addressLimit: 2000,
                language: "nl",
                onComplete: function (event) {
                    const addressCount = event.currentSelection
                        .map((a) => a.selectedAddresses.length)
                        .reduce((partialSum, a) => partialSum + a, 0);
                    demo.setOptions({ isModalOpen: false });
                    console.log(event.currentSelection);
                    result_count.innerHTML = addressCount;
                },
                onCancel: function (event) {
                    console.log(event);
                },
                onError: function (event) {
                    console.log(event);
                },
                onModalRequestClose: function () {
                    console.log("request to close!");
                    // Update options with the state of the modal
                    demo.setOptions({ isModalOpen: false });
                },
            });
        });
    }
)();
